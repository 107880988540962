import React, { useState } from "react"
import { Header, Icon, Breadcrumb, Card, CardGroup, CardContent, Button, CardHeader, CardMeta, CardDescription, Label } from "semantic-ui-react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import queryString from 'query-string'
import _ from "lodash"
// import SelfCheckRow from "../components/SelfCheckRow"

const makeRandomOptions = (misconceptions) => {
  return misconceptions.map(mc=>({ pl: mc.fields.pl, mc: mc.fields.name, showCorrect: Math.random()<0.5}));
}
const makeSelections = (misconceptions) => {
  return misconceptions.map(mc=>({ pl: mc.fields.pl, mc: mc.fields.name, selected: null}));
}
const makeFeedbackRequests = (misconceptions) => {
  return misconceptions.map(mc=>({ pl: mc.fields.pl, mc: mc.fields.name, feedbackRequested: false}));
}

const getAvatarUrl = (pl, mc, side, state) => {
  function stringToHash(string) { 
    let hash = 0;
    if (string.length == 0) return hash; 
    for (let i = 0; i < string.length; i++) {
        const char = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
    }      
    return hash; 
  }
  function get(list) {
    const index = Math.abs(stringToHash(pl + mc + side)) % list.length;
    return list[index];
  }
  // generic character properties
  const tops = [/*'NoHair',*/ 'Hat', 'Hijab', 'Turban', /*'WinterHat1', 'WinterHat2', 'WinterHat3', 'WinterHat4',*/ 'LongHairBigHair', 'LongHairBob', 'LongHairBun', 'LongHairCurly', 'LongHairCurvy', 'LongHairDreads', 'LongHairFrida', 'LongHairFro', 'LongHairFroBand', 'LongHairNotTooLong', 'LongHairShavedSides', 'LongHairMiaWallace', 'LongHairStraight', 'LongHairStraight2', 'LongHairStraightStrand', 'LongHairFrida', 'ShortHairDreads01', 'ShortHairDreads02', 'ShortHairFrizzle', 'ShortHairShaggyMullet', 'ShortHairShortCurly', 'ShortHairShortFlat', 'ShortHairShortRound', 'ShortHairShortWaved', /*'ShortHairSides',*/ 'ShortHairTheCaesar', 'ShortHairTheCaesarSidePart'];
  const accessories = ['Blank', /*'Kurt',*/ 'Prescription01', 'Prescription02', 'Round', /*'Sunglasses', 'Wayfarers'*/];
  const hairColors = ['Auburn', 'Black', 'Blonde', 'BlondeGolden', 'Brown', 'BrownDark', 'PastelPink', 'Platinum', 'Red', 'SilverGray'];
  const clothes = ['BlazerShirt', 'BlazerSweater', 'CollarSweater', /*'GraphicShirt',*/ 'Hoodie', /*'Overall',*/ 'ShirtCrewNeck', 'ShirtScoopNeck', 'ShirtVNeck'];
  const fabricColors = ['Black', 'Blue01', 'Blue02', 'Blue03', /*'Gray01',*/ 'Gray02', 'Heather', /*'PastelBlue', 'PastelGreen', 'PastelOrange', 'PastelRed', 'PastelYellow',*/ 'Pink', 'Red', 'White'];
  const skins = ['Tan', 'Yellow', 'Pale', 'Light', 'Brown', 'DarkBrown', 'Black'];
  // emotion-specific properties
  const eyes = {
    // 'Close', 'Cry', 'Default', 'Dizzy', 'EyeRoll', 'Happy', 'Hearts', 'Side', 'Squint', 'Surprised', 'Wink', 'WinkWacky'
    question: ['Close', 'Default', 'Side', 'Squint', 'Surprised'],
    correct: ['Happy', 'Hearts', 'Wink'],
    incorrect: ['Cry', 'EyeRoll']
  };
  const eyebrows = {
    // 'Angry', 'AngryNatural', 'Default', 'DefaultNatural', 'FlatNatural', 'RaisedExcited', 'RaisedExcitedNatural', 'SadConcerned', 'SadConcernedNatural', 'UnibrowNatural', 'UpDown', 'UpDownNatural'
    question: ['Default', 'DefaultNatural', 'FlatNatural', 'UnibrowNatural', 'UpDown', 'UpDownNatural'],
    correct: ['RaisedExcited', 'RaisedExcitedNatural'],
    incorrect: ['Angry', 'AngryNatural', 'SadConcerned', 'SadConcernedNatural'],
  };
  const mouths = {
    // 'Concerned', 'Default', 'Disbelief', 'Eating', 'Grimace', 'Sad', 'ScreamOpen', 'Serious', 'Smile', 'Tongue', 'Twinkle', 'Vomit'
    question: ['Serious'],
    correct: ['Default', 'Smile', 'Twinkle'],
    incorrect: ['Concerned', 'Disbelief', 'Sad'],
  };
  return "https://avataaars.io/?avatarStyle=Transparent&" + 
    "topType=" + get(tops) +
    "&accessoriesType=" + get(accessories) +
    "&hairColor=" + get(hairColors) + 
    "&facialHairType=Blank&clotheType=" + get(clothes) +
    "&clotheColor=" + get(fabricColors) +
    "&skinColor=" + get(skins) +
    "&eyeType=" + get(eyes[state]) +
    "&eyebrowType=" + get(eyebrows[state]) +
    "&mouthType=" + get(mouths[state]);
}


const SelfCheckPage = ({data, location}) => {
  const search = location.search ? queryString.parse(location.search) : {};

  const textbooks = data.textbooks.nodes;
  const allMisconceptions = data.misconceptions.nodes;
  // filter all misconceptions based on provided URL search query
  const misconceptions = allMisconceptions.filter(mc=> 
    (!search.pl || search.pl===mc.fields.pl) &&
    (!search.status || search.status===mc.frontmatter.status) &&
    (!search.concepts || search.concepts.split(',').every(c => mc.frontmatter.concepts && mc.frontmatter.concepts.includes(c))) &&
    (!search.textbook || (mc.frontmatter.text && mc.frontmatter.text.some(t => 
      t.id===search.textbook.split('/')[0] && t.sections.some(s => s===search.textbook.split('/')[1])
    )))
  );

  const [options] = useState(makeRandomOptions(misconceptions));
  const [selections,setSelections] = useState(makeSelections(misconceptions));
  const [feedbackRequests,setFeedbackRequests] = useState(makeFeedbackRequests(misconceptions));

  const select = (pl, mc, what) => {
    setSelections(ss=> [ ...ss.filter(s=>s.pl!=pl || s.mc!=mc), {pl: pl, mc: mc, selected: what} ] );
  };
  const requestFeedback = (pl, mc) => {
    if (window.plausible) {
      const correctSelection = options.find(o => o.pl === pl && o.mc === mc).showCorrect ? 'left' : 'right';
      const userSelection = selections.find(o => o.pl === pl && o.mc === mc).selected;
      let correctness = 'unsure';
      if (userSelection !== 'middle') {
        correctness = correctSelection === userSelection ? 'correct' : 'wrong';
      }
      const misconception = `${pl}_${mc}_${correctness}`;
      window.plausible('SelfCheck', {props: {misconception}})
    }
    setFeedbackRequests(frs=> [ ...frs.filter(fr=>fr.pl!=pl || fr.mc!=mc), {pl: pl, mc: mc, feedbackRequested: true} ] );
  }

  return <Layout>
    <SEO title="Conceptual Self-Check" />
    <div style={{ marginLeft: "10px", marginTop: "50px", marginBottom: "50px" }}>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          Home
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Conceptual Self-Check</Breadcrumb.Section>
      </Breadcrumb>

      <Header as="h1" dividing>
        <Icon name="tasks" />
        <Header.Content>
          Conceptual Self-Check
          <Header.Subheader>Uncover your misconceptions by answering conceptual questions</Header.Subheader>
        </Header.Content>
      </Header>

      <div style={{marginBottom: '20px'}}>
      {search.textbook ? (
        <>
          Textbook Chapter: &nbsp;&nbsp;
          <Label
            as={Link}
            to={`/textbooks/${search.textbook}`}
          >
            {textbooks.find(t=>t.parent.name===search.textbook.split('/')[0]).frontmatter.title}, Chapter {search.textbook.split('/')[1]}
          </Label>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ): null}
      {search.pl ? (
        <>
          Language: &nbsp;&nbsp;
          <Label color='red'>{search.pl}</Label>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ): null}
      {search.concepts ? (
        <>
          Concepts: &nbsp;&nbsp;
          {search.concepts.split(',').map((c) => (
            <Label
              key={`concepts-${c}`}
              as={Link}
              to={`/concepts/${_.kebabCase(c)}`}
              color="brown"
            >
              {_.startCase(c)}
            </Label>
          ))}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {search.status ? (
        <>
          Status: &nbsp;&nbsp;
          <Label color='yellow'>{search.status}</Label>
        </>
      ): null}
      </div>
      <Header as="h2">
        Answer {options.length} Concept Question{options.length>1?"s":""}
      </Header>
      {options.map(o => {
        const mc = misconceptions.find(mc => mc.fields.name===o.mc && mc.fields.pl===o.pl);
        const leftStatement = o.showCorrect ? mc.frontmatter.shortCorrection : mc.frontmatter.shortDescription;
        const rightStatement = !o.showCorrect ? mc.frontmatter.shortCorrection : mc.frontmatter.shortDescription;
        const selection = selections.find(s => s.mc===o.mc && s.pl===o.pl);
        const unsure = selection.selected==='middle';
        const correct = (selection.selected==='left' && o.showCorrect) || (selection.selected==='right' && !o.showCorrect) ;
        const incorrect = (selection.selected==='left' && !o.showCorrect) || (selection.selected==='right' && o.showCorrect) ;
        const feedbackRequest = feedbackRequests.find(fr => fr.mc===o.mc && fr.pl===o.pl);
        return (
          <>
            <CardGroup key={o.pl+"/"+o.mc}>
              <Card className='horizontal' style={selection.selected==='left'?{background: 'lightblue'}:{}}  link as='div' onClick={()=>select(o.pl, o.mc, 'left')}>
                <div className='image'>
                  <img src={getAvatarUrl(o.pl, o.mc, 'left', !feedbackRequest.feedbackRequested ? 'question' : o.showCorrect ? 'correct' : 'incorrect')} />
                </div>
                <CardContent>
                  <strong>In {mc.fields.pl}:</strong><br/>
                  {leftStatement || "?"}
                </CardContent>
                <Button primary={selection.selected==='left'}>Select</Button>
              </Card>
              <Card style={selection.selected==='middle'?{background: 'lightblue'}:{}} link as='div' onClick={()=>select(o.pl, o.mc, 'middle')}>
                <CardContent style={{textAlign: 'center'}}>
                  &nbsp;<br/>
                  <i className="huge black question circle outline icon"></i><br/><br/>
                  I am not sure what's right.
                </CardContent>
                <Button primary={selection.selected==='middle'}>Select</Button>
              </Card>
              <Card className='horizontal' style={selection.selected==='right'?{background: 'lightblue'}:{}}  link as='div' onClick={()=>select(o.pl, o.mc, 'right')}>
                <div className='image'>
                  <img src={getAvatarUrl(o.pl, o.mc, 'right', !feedbackRequest.feedbackRequested ? 'question' : !o.showCorrect ? 'correct' : 'incorrect')} />
                </div>
                <CardContent>
                  <strong>In {mc.fields.pl}:</strong><br/>
                  {rightStatement || "?"}
                </CardContent>
                <Button primary={selection.selected==='right'}>Select</Button>
              </Card>
              { unsure || incorrect || correct ? 
                (
                  feedbackRequest.feedbackRequested ?
                  (
                    <>
                    {
                      unsure ? (
                        <Card style={{background: 'hsl(50, 80%, 70%)'}}>
                          <CardContent>
                            <CardHeader>Potential Misconception</CardHeader>
                            <CardMeta>{mc.fields.name}</CardMeta>
                            <CardDescription>
                              <strong>Here's what's right in {mc.fields.pl}:</strong><br/>
                              {mc.frontmatter.shortCorrection || '(shortCorrection missing)'}
                            </CardDescription>
                          </CardContent>
                          <Button as={Link} to={'/misconceptions/'+mc.fields.pl+'/'+mc.fields.name}  color="yellow">Learn More</Button>
                        </Card>
                      ) : null
                    }
                    {
                      incorrect ? (
                        <Card style={{background: 'hsl(0, 80%, 70%)'}}>
                          <CardContent>
                            <CardHeader>Misconception</CardHeader>
                            <CardMeta>{mc.fields.name}</CardMeta>
                            <CardDescription>
                              <strong>Here's what's right in {mc.fields.pl}:</strong><br/>
                              {mc.frontmatter.shortCorrection || '(shortCorrection missing)'}
                            </CardDescription>
                          </CardContent>
                          <Button as={Link} to={'/misconceptions/'+mc.fields.pl+'/'+mc.fields.name}  color="red">Learn More</Button>
                        </Card>
                      ) : null
                    }
                    {
                      correct ? (
                        <Card style={{background: 'hsl(100, 80%, 70%)'}}>
                          <CardContent>
                            <CardHeader>Correct Conception</CardHeader>
                            <CardDescription>
                              <strong>In {mc.fields.pl}:</strong><br/>
                              {mc.frontmatter.shortCorrection || '(shortCorrection missing)'}
                            </CardDescription>
                          </CardContent>
                          <Button as={Link} to={'/misconceptions/'+mc.fields.pl+'/'+mc.fields.name} color="green">Learn More</Button>
                        </Card>
                      ) : null
                    }
                    </>
                  )
                  :
                  (
                    <Card link as='div' onClick={()=>requestFeedback(o.pl, o.mc)}>
                      <CardContent>
                        <CardHeader>Misconception or Not?</CardHeader>
                        <CardContent>
                          Click here to see the correct conception.
                        </CardContent>
                      </CardContent>
                    </Card>
                  )
                )
                :
                null
              }

            </CardGroup>
          </>
        );
      })}
    </div>
  </Layout>;
}

export default SelfCheckPage

export const pageQuery = graphql`
  query {
    misconceptions: allMdx(
      filter: {
        fileAbsolutePath: { glob: "**/src/data/misconceptions/**/index.md" }
      }
      sort: { fields: fields___name, order: ASC }
    ) {
      nodes {
        fields {
          slug
          name
          pl
        }
        frontmatter {
          status
          concepts
          shortDescription
          shortCorrection
          text {
            id
            sections
          }
        }
      }
    }
    textbooks: allMdx(
      filter: {
        fileAbsolutePath: { glob: "**/src/data/textbooks/*.md" }
      }
    ) {
      nodes {
        frontmatter {
          title
          subtitle
          edition
          pl
          home
          baseUrl
          sections {
            section
            title
            url
          }
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }
  }
`
